* {
    /* margin: 0; */
    /* padding: 0; */
    /* box-sizing: border-box; */
  }
  
  .heading_header {
    margin-left: 10px;
  }
  
  .heading_h2 {
  
    color: #fee772;
  }
  
  .heading_prizes {
    color: #4acc76;
  }
  
  .heading_bg {
    padding-top: 10px;
    background-image: url("/public/images/headerbg.png");
  
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: contain;
    height: max-content;
    background-attachment: local;
  }
  
  .luckland_img {
    margin-top: -134px;
  }
  
  @media only screen and (max-width: 767px) {
    input {
      display: block;
      width: 100%;
      max-width: 100%;
    }
  
    .lady_img {
      margin-left: 50px;
    }
  
    .lady_img {
      width: 200px;
    }
  /* .duck{ 
     position: absolute;
  bottom: 42px;
  left: 252px;
  transform: scale(0.5);
  }  */
    .heading_bg {
      height: max-content;
    }
  
    .luckland_img {
      margin-top: -52px;
      width: 200px;
      height: auto;
    }
  
    .something {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  
    .all_details p {
      color: white;
      font-family: "Arial", Sans-serif;
      font-size: 15px !important;
      font-weight: 100;
    }
  
  
    .form_data1{
    display: block;
    max-width: 100%;
    flex-wrap: nowrap;
   
  } 
  
    .form_div input {
      display: block;
    }
  
    ion-icon  {
      font-size: 12px;
      padding: 5px;
    border-radius: 10px !important;
      /* border: 1px solid #ac23fb; */
      color: #fff;
      background-color: #336;;
    }
    .footer_div{
  display: flex;
  flex-direction: column;
  font-size: 10px;
  font-weight: 300;
    }
  
    
    .playnow_img{
      width: 170px;
      height: 60px;
    }
    
  
    .row {
      margin-left:0px ;
      margin-right: 0px !important;
      padding-right: 0px!important;
      padding-left: 0px;
    }
    select {
      display: block;
      width: 100%;
      max-width: 100%;
      background-color: transparent;
      background-color: #4B008200; 
      border-color: #2E34B4;
      border-width: 3px 3px 3px 3px;
      border-radius: 10px 10px 10px 10px;
      outline: none;
      color: #fff;
      height: 40px;
    }
  
  }
  /* @media only screen and (min-width: 768px) {...} */
  
  @media only screen and (min-width: 768px) {
  
    .form_data1 {
      display: flex;
      /* max-width: 30%; */
      flex-wrap: wrap;
   
      /* border: 1px solid white; */
    }
    select {
      display: block;
      width: 395px;
      max-width: 100%;
      background-color: transparent;
      background-color: #4B008200; 
      border-color: #2E34B4;
      border-width: 3px 3px 3px 3px;
      border-radius: 10px 10px 10px 10px;
      outline: none;
      color: #fff;
      height: 40px;
    }
    /* .submit_but{
      margin-left: 150px;
    } */
    /* .lady_div {
      padding-left: 200px;
    } */
  }
   /* @media only screen and (min-width: 992px) {
    .form_data1 {
      display: flex;
      /* min-width:60%; */
      /* flex-wrap: wrap;
      padding: 10px;
      border: 1px solid white; */
    /* }
  }   */
  /* @media only screen and(min-width:1237){
    .form_data1 {
      display: flex;
      /* max-width: 60%; */
      /* flex-wrap: wrap; */
      /* padding: 10px; */
      /* border: 1px solid white; */
    /* }
  } */ 
  
  
  /* @media only screen and (min-width: 1099px) and (max-width: 1250px){
    .form_data1 {
      display: flex;
  
      max-width: 60%;
      flex-wrap: wrap;
      padding: 10px;
      /* border: 1px solid white; */
    /* }
  } */ 
  
  
  
  
  
  
  @media only screen and (min-width:992px) and (max-width:1062px){
    .form_data1{
      display: flex;
  
      /* max-width: 60%; */
      flex-wrap: wrap;
      /* border: 1px solid #fff; */
    }
    select {
      display: block;
      width: 395px;
      max-width: 100%;
      background-color: transparent;
      background-color: #4B008200; 
      border-color: #2E34B4;
      border-width: 3px 3px 3px 3px;
      border-radius: 10px 10px 10px 10px;
      outline: none;
      color: #fff;
      height: 40px;
    }
    
  }
  
  /* @media only screen and (min-width:1151) and (max-width:1214){
    .submitbut{
      margin-left: 300px;
    }
  } */
  
  @media only screen and (min-width:1200) and (max-width:1213px){
    .form_data1{
      display: flex;
  
      max-width: 60%;
      flex-wrap: wrap;
      /* border: 1px solid #fff; */
    }
    select {
      display: block;
      width: 395px;
      max-width: 100%;
      background-color: transparent;
      background-color: #4B008200; 
      border-color: #2E34B4;
      border-width: 3px 3px 3px 3px;
      border-radius: 10px 10px 10px 10px;
      outline: none;
      color: #fff;
      height: 40px;
    }
    /* .submitbut{
      margin-left: 100px;
    } */
  }
  @media only screen and (min-width:1214px)and (max-width:1220px){
    .form_data1{
      display: flex;
  
      max-width: 74%;
      flex-wrap: wrap;
      /* border: 1px solid #fff; */
    }
    select {
      display: block;
      width: 395px;
      max-width: 100%;
      background-color: transparent;
      background-color: #4B008200; 
      border-color: #2E34B4;
      border-width: 3px 3px 3px 3px;
      border-radius: 10px 10px 10px 10px;
      outline: none;
      color: #fff;
      height: 40px;
    }
  }
  
   @media only screen and (min-width: 1220px) {
    .form_data1 {
      display: flex;
      flex-wrap: wrap;
      max-width: 72%;
      /* padding: 10px; */
      
    }
    .footer_div{
      display: flex;
      
    }
  
    select {
      display: block;
      width: 395px;
      max-width: 100%;
      background-color: transparent;
      background-color: #4B008200; 
      border-color: #2E34B4;
      border-width: 3px 3px 3px 3px;
      border-radius: 10px 10px 10px 10px;
      outline: none;
      color: #fff;
      height: 40px;
    }
  
  
  } 
  @media only screen and (min-width: 1690px) {
    .form_data1 {
      display: flex;
      flex-wrap: wrap;
      max-width: 60%;
      /* padding: 10px; */
     
   }
   select {
    display: block;
    width: 395px;
    max-width: 100%;
    background-color: transparent;
    background-color: #4B008200; 
    border-color: #2E34B4;
    border-width: 3px 3px 3px 3px;
    border-radius: 10px 10px 10px 10px;
    outline: none;
    color: #fff;
    height: 40px;
  }
  } 
  .button_div {
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
  }
  
  .body_bg {
    padding-top: 40px;
    background-image: url("/public/images/background-scaled.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    width: auto;
    height: auto;
  }
  
  .enter_info {
    font-family: "Roboto", Sans-serif;
    font-size: 33px;
    font-weight: 600;
    text-shadow: 1px 1px 10px #fff, 1px 1px 5px #fff;
  }
  
  .para {
    color: #56E1F9;
    font-family: "Roboto", Sans-serif;
    font-size: 17px;
    font-weight: 600;
  }
  
  /* .lady_img{
    margin-left: 200px;
  } */
  .form_data {
    display: flex;
    max-width: 60%;
    flex-wrap: wrap;
  
  
    /* border: 1px solid white; */
  }
  
  input {
    background-color: transparent;
    /* background-color: #4B008200; */
    border-color: #2E34B4;
    border-width: 3px 3px 3px 3px;
    border-radius: 10px 10px 10px 10px;
    outline: none;
    color: #fff;
    height: 40px;
  }
  
  .submit_but {
    /* margin-left: 170px; */
    padding: 10px 40px;
    border-radius: 45px;
    background-color: #ac23fb;
    color: #fff;
  }
  
  /* .diamond_duck{
  background-image: url('/public/images/win-pile.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  
  height: 100vh;
  } */
  .heading_img2{
    position: relative;
    left: 100px;
    right: 10px;
  }
  body{
    /* overflow-y: hidden;  */
    overflow-x: hidden !important;
  }
  .alllogos {
    width: 60vw;
    height: auto;
  }
  
  .diamond_duck {
    width: 100vw;
    height: auto;
  }
  .playnow{
    display: flex;
    justify-content: center;
    align-items: center;
   
  
  }
  .line{
    padding-top: 50px;
  width: 100%;
  padding-bottom: 50px;
  }
  .all_details p {
    margin-left: 20px;
    color: white;
    font-family: "Arial", Sans-serif;
    font-size: 20px;
    font-weight: 600;
  }
  
  .all_details {
    /* display: flex;
    justify-content: flex-start; */
  }
  
  .last_part {
  
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 30px;
  }
  
  .footer_div {
    background-color: #343434;
  }
  
  .row_div {
    /* margin-right: 0px; */
  }
  
  .row {
    margin-left:0px ;
    margin-right: 0px !important;
    padding-right: 0px!important;
    padding-left: 0px;
  }
  .duck{
    position:absolute;
    bottom: -117px;
    left: 252px;
    transform: scale(0.7);
  }
  /* .glow{
    -webkit-animation: glow 1s ease-in-out infinite alternate;
    -moz-animation: glow 1s ease-in-out infinite alternate;
    animation: glow 1s ease-in-out infinite alternate;
  }
  @-webkit-keyframes glow {
    from {
      text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073, 0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
    }
    to {
      text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6, 0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
    }
  } */
  .footer_div{
    color: #989898;
      font-family: "Arial", Sans-serif;
      font-size: 18px;
      font-weight: 500;
  
  background-color: #343434 ;
  padding: 15px;
  display: flex;
  /* flex-direction: column; */
  /* flex-direction: row; */
  justify-content: center;
  align-items: center;
  }
  .footer_div a{
    color: #fff;
  }
  .footer_div p{
    /* letter-spacing: 1.5px;
    word-spacing: 2px; */
  }
  .mylabel {
    font-size: 17px;
    font-weight: 600;
  }
  ion-icon .facebook {
    font-size: 32px;
    padding: 5px;
  border-radius: 10px !important;
    /* border: 1px solid #ac23fb; */
    color: #fff;
    background-color: #336;;
  }
    
  .playnow_img{
    width: 1250x;
    height: 80px;
  }
  select {
    /* display: block;
    width: 395px;
    max-width: 100%; */
    background-color: transparent;
    background-color: #4B008200; 
    border-color: #2E34B4;
    border-width: 3px 3px 3px 3px;
    border-radius: 10px 10px 10px 10px;
    outline: none;
    color: #fff;
    height: 40px;
  }
  
  option {
    background: #2E34B4;
  }
  .submitbut{
    margin-top: 10px;
  }